<template>
  <v-container fluid>
    <v-card>
      <v-card-title>ONLINE PLAYERS - REALTIME UPDATE</v-card-title>
      <v-card-text>
        <span class="mx-1 green--text text-h6">Online: {{online}}</span>
        <span class="mx-1 orange--text text-h6">Ingame: {{ingame}}</span>
        <span class="mx-1 red--text text-h6">Lobby: {{lobby}}</span>
        <span class="mx-1 yellow--text text-h6">Vip Key: {{vk}}</span>
        <span class="mx-1 green--text text-h6">Public Key: {{pk}}</span>
      </v-card-text>
      <v-card-text>
        <v-btn
          color="green"
          @click="filter.type = 'all'"
          class="mx-1"
        >
          <v-icon
            left
          >
            {{filter.type === 'all' ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'}}
          </v-icon>
          ALL
        </v-btn>
        <v-btn
            color="red"
            @click="filter.type = 'lobby'"
            class="mx-1"
        >
          <v-icon
              left
          >
            {{filter.type === 'lobby' ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'}}
          </v-icon>
          LOBBY
        </v-btn>
        <v-btn
            color="orange"
            @click="filter.type = 'ingame'"
            class="mx-1"
        >
          <v-icon
              left
          >
            {{filter.type === 'ingame' ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'}}
          </v-icon>
          IN-GAME
        </v-btn>
        <v-btn
            color="orange darken-2"
            @click="filter.type = 'same-game'"
            class="mx-1"
        >
          <v-icon
              left
          >
            {{filter.type === 'same-game' ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'}}
          </v-icon>
          SAME GAME
        </v-btn>
        <v-btn
            color="blue"
            @click="filter.key_type = 'all'"
            class="mx-1"
        >
          <v-icon
              left
          >
            {{filter.key_type === 'all' ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'}}
          </v-icon>
          ALL TYPE
        </v-btn>
        <v-btn
            color="lime darken-1"
            @click="filter.key_type = 'vip'"
            class="mx-1"
        >
          <v-icon
              left
          >
            {{filter.key_type === 'vip' ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'}}
          </v-icon>
          VIP
        </v-btn>
        <v-btn
            color="green"
            @click="filter.key_type = 'public'"
            class="mx-1"
        >
          <v-icon
              left
          >
            {{filter.key_type === 'public' ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'}}
          </v-icon>
          PUBLIC
        </v-btn>
        <v-text-field
            v-model="filter.key"
            label="Search by key"
            class="mx-1"
            clearable
        />
      </v-card-text>
      <v-card-text v-if="filter.type === 'same-game'">
        <v-row justify="center">
          <v-expansion-panels accordion>
            <v-expansion-panel
                v-for="(item,i) in GroupedPlayers"
                :key="i"
            >
              <v-expansion-panel-header :class="parseColor(item.key_type)"> ({{item.length}}) [{{item[0]['gameid']}}]</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left blue--text text-h6">
                        KEY
                      </th>
                      <th class="text-left blue--text text-h6">
                        Seller
                      </th>
                      <th class="text-left blue--text text-h6">
                        Type
                      </th>
                      <th class="text-left blue--text text-h6">
                        Game ID
                      </th>
                      <th class="text-left blue--text text-h6">
                        Player ID
                      </th>
                      <th class="text-left blue--text text-h6">
                        Key Type
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="(player,index) in item"
                        :key="index"
                    >
                      <td>
                        {{ player.key }}
                      </td>
                      <td :class="parseColor(player.key_type)">{{ loadSellerDetails(player.seller).username }}</td>
                      <td :class="parseColor(player.player_type)" v-if="player.player_type">{{ player.player_type.toUpperCase() }}</td>
                      <td v-else>INVALID DATA</td>
                      <td>{{ player.gameid }}</td>
                      <td>{{ player.playerid }}</td>
                      <td :class="parseColor(player.key_type)">{{ player.key_type.toUpperCase() }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left blue--text text-h6">
                KEY
              </th>
              <th class="text-left blue--text text-h6">
                Seller
              </th>
              <th class="text-left blue--text text-h6">
                Version
              </th>
              <th class="text-left blue--text text-h6">
                Type
              </th>
              <th class="text-left blue--text text-h6">
                Game ID
              </th>
              <th class="text-left blue--text text-h6">
                Player Info
              </th>
              <th class="text-left blue--text text-h6">
                Key Type
              </th>
              <th class="text-left blue--text text-h6">
                Blacklist
              </th>
              <th class="text-left blue--text text-h6">
                Get Data
              </th>
<!--              <th class="text-left blue&#45;&#45;text text-h6">-->
<!--                Seller ID-->
<!--              </th>-->
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item,index) in Players"
                :key="index"
            >
              <td>
                {{ item.key }}
                <template v-if="item.player_info.pkey_name">
                  <v-chip
                      color="green"
                      text-color="white"
                      small
                  >
                    {{item.player_info.pkey_name}}
                  </v-chip>
                </template>
              </td>
              <td :class="parseColor(item.key_type)">{{ loadSellerDetails(item.seller).username }}</td>
              <td :class="parseColor(item.player_type)">{{ item.player_info ? item.player_info.version : "No Data" }}</td>
              <td :class="parseColor(item.player_type)">{{ item.player_type.toUpperCase() }}</td>
              <td>{{ item.gameid }}</td>
              <td>
                  <div v-if="item.playerid">
                      <span class="green--text">ID: </span>
                      {{ item.playerid }}
                  </div>
                  <template v-if="item.player_info">
                    <div v-if="item.player_info.playername">
                        <span class="orange--text">Name: </span>
                        {{item.player_info.playername}}
                    </div>
                    <div v-if="item.player_info.playerkill && item.player_info.playerkill !== -1">
                        <span class="red--text">Kill: </span>
                        {{item.player_info.playerkill}}
                    </div>
<!--                    <div v-if="item.player_info['last_online']">-->
<!--                        Last Online: {{ (new Date(item.player_info['last_online']*1000)).toLocaleString() }}-->
<!--                    </div>-->
                  </template>
              </td>
              <td :class="parseColor(item.key_type)">{{ item.key_type.toUpperCase() }}</td>
              <td>
                <div>
                  <v-btn
                      color="red"
                      @click="doAdd2Blacklist(item.key)"
                      v-if="item.key_type === 'vip'"
                      class="ma-1"
                  >
                    <v-icon
                        left
                    >
                      mdi-account-off
                    </v-icon>
                    Blacklist
                  </v-btn>
                </div>
                <div>
                  <v-btn
                      color="red"
                      @click="doBanHWID(item.player_info.hwid)"
                      v-if="item.player_info && item.player_info.hwid"
                      class="ma-1"
                  >
                    <v-icon
                        left
                    >
                      mdi-account-off
                    </v-icon>
                    Ban By HWID
                  </v-btn>
                </div>
                <div>
                  <v-btn
                    color="orange"
                    @click="doKick(item.key_id)"
                    :disabled="state.doKick === item.key_id"
                    :loading="state.doKick === item.key_id"
                    class="ma-1"
                  >
                    <v-icon
                        left
                    >
                      mdi-hammer
                    </v-icon>
                    Kick
                  </v-btn>
                </div>
              </td>
              <td>
                <v-btn
                    color="blue"
                    @click="doGetData(item.key_id)"
                    :disabled="state.getData === item.key_id"
                    :loading="state.getData === item.key_id"
                >
                  <v-icon
                      left
                  >
                    mdi-account-arrow-right-outline
                  </v-icon>
                  Get Data
                </v-btn>
              </td>
<!--              <td>{{ item.seller }}</td>-->
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-overlay :value="Overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <BanHWID ref="ban_hwid" />
  </v-container>
</template>

<script>
import {fetchData} from "../helpers";
// import io from "socket.io-client";
import BanHWID from "../components/BanHWID";
// import { mapActions } from 'vuex'
import Notice from '../helpers/notice';
export default {
  name: "Players",
  components: {
    BanHWID,
  },
  data(){
    return {
      players: [],
      online: 0,
      ingame: 0,
      lobby: 0,
      vk: 0,
      pk: 0,
      filter:{
        type: 'all',
        key_type: 'all',
        key: null,
      },
      sellersLists: [],
      auto: undefined,
      Overlay: true,
      socket: null,
      state:{
        getData: false,
      }
    }
  },
  computed:{
    GroupedPlayers(){
      const players = this.players.filter((item)=>item['gameid']);
      let groupedPlayers = players.reduce((acc, player) => {
        if (!acc[player['gameid']]) {
          acc[player['gameid']] = [];
        }
        acc[player['gameid']].push(player);
        return acc;
      }, {});

      // Convert the object to an array
      let entries = Object.entries(groupedPlayers);

      // Sort the array by the length of the player arrays
      entries.sort((a, b) => b[1].length - a[1].length);

      // Convert the array back to an object
      groupedPlayers = Object.fromEntries(entries);

      return groupedPlayers;
    },
    // GroupedPlayers(){
    //   const players = this.players.filter((item)=>item['gameid']);
    //   const groupedPlayers = players.reduce((acc, player) => {
    //     if (!acc[player['gameid']]) {
    //       acc[player['gameid']] = [];
    //     }
    //     acc[player['gameid']].push(player);
    //     return acc;
    //   }, {});
    //   return groupedPlayers;
    // },
    Players(){
      return this.players.filter((item)=>{
        if(this.filter.key !== null && this.filter.key.length > 0){
          return item.key.includes(this.filter.key);
        }
        if(this.filter.type === 'all' && this.filter.key_type === 'all'){
          return true;
        }
        if(this.filter.type === 'all' && this.filter.key_type !== 'all'){
          return item.key_type === this.filter.key_type;
        }
        if(this.filter.type !== 'all' && this.filter.key_type === 'all'){
          return item.player_type === this.filter.type;
        }
        if(this.filter.type !== 'all' && this.filter.key_type !== 'all'){
          return item.player_type === this.filter.type && item.key_type === this.filter.key_type;
        }
      });
    }
  },
  methods:{
    // ...mapActions('alert', ['success', 'error']),
    doBanHWID: function(hwid){
      this.$refs.ban_hwid.openModal(hwid);
    },
    // doGetData(key_id){
    //   this.state.getData = key_id;
    //   this.socket.emit('req-get_data', key_id);
    // },
    doGetData(key_id){
      this.state.getData = key_id;
      fetchData(`${this.$API}/api/online/get_data`,{ key: key_id }).then((json)=>{
        let message = (json.message) ? json.msg:"Server Error !!!";
        if(json.success == true){
          //this.success("Request Success !!!");
          Notice.success({message: "Request Success !!!"});
        }else{
          //this.error(message);
          Notice.error({message});
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.state.getData = false;
      });
    },
    doKick(key_id){
      this.state.doKick = key_id;
      fetchData(`${this.$API}/api/online/kick`,{ key: key_id }).then((json)=>{
        let message = (json.message) ? json.msg:"Server Error !!!";
        if(json.success == true){
          //this.success("Request Success !!!");
          Notice.success({message: "Request Success !!!"});
        }else{
          //this.error(message);
          Notice.error({message});
        }
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.state.doKick = false;
      });
    },
    // doKick(key_id){
    //   this.state.doKick = key_id;
    //   this.socket.emit('req-do_kick', key_id);
    // },
    doAdd2Blacklist: function(key_data){
      this.Overlay = true;
      this.$confirm(`Do you really want to do this action ? This data can't restore !`,{ title: 'Warning' }).then(res => {
        if(res === true){
          fetchData(`${this.$API}/api/Blacklist/add_from_key_data`,{ key_data }).then((json)=>{
            let message = (json.msg) ? json.msg:"Server Error !!!";
            if(json.success == true){
              this.success(message);
            }else{
              this.error(message);
            }
          },(err)=>{
            if(err === 401){
              this.logout();
            }else{
              this.error('SERVER ERROR !!!');
            }
          }).finally(()=>{
            this.Overlay = false;
          });
        }
      });
    },
    loadSellerDetails(seller_id){
      let seller = {
        seller_id: null,
        username: '[PUBLIC KEY]',
      };
      this.sellersLists.forEach((item)=>{
        if(item.seller_id === seller_id.replace('seller_','')*1){
          seller = item;
        }
      });
      return seller;
    },
    fetchSellers: function(){
      this.Overlay = true;
      fetchData(`${this.$API}/api/Seller/getList`).then((json)=>{
        this.sellersLists = json;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
    parseColor(type){
      switch (type) {
        case "public":
          return "green--text";
        case "ingame":
          return "orange--text";
        case "lobby":
          return "red--text";
        default:
          return "yellow--text";
      }
    },
    doFetch(){
      fetchData(`${this.$API}/api/new-onlines`)
        .then((json)=>{
          const {online, ingame, lobby, data, vk, pk} = json;
          this.online = online;
          this.ingame = ingame;
          this.lobby = lobby;
          this.vk = vk;
          this.pk = pk;
          this.players = data;
        }).finally(()=>{
          setTimeout(()=>{
            this.doFetch();
          },30000);
        });
    },
    parseOnline(e){
      if(e.data){
        try{
          //console.log(e.data);
          const {online, ingame, lobby, data: players, vk, pk} = JSON.parse(e.data);
          this.online = online;
          this.ingame = ingame;
          this.lobby = lobby;
          this.vk = vk;
          this.pk = pk;
          this.players = players;
          this.Overlay = false;
        }catch(e){
          console.error(e);
        }
      }
    }
  },
  mounted() {
    // this.doFetch();
    this.Overlay = true;
    this.fetchSellers();
    const eventSource = new EventSource(`${this.$API}/sse/online`);
    // const eventSource = new EventSource(`http://localhost:6100`);
    eventSource.addEventListener("online-list", this.parseOnline);
    // const socket = io(`${this.$API}`);
    // const app = this;
    // app.socket = socket;
    // socket.on('connect', () => {
    //   app.auto = setInterval(() => {
    //     socket.emit('req-onlines_full');
    //   }, 1000);
    // });
    // socket.on('res-onlines_full', (data) => {
    //   const {online, ingame, lobby, data: players, vk, pk} = data;
    //   app.online = online;
    //   app.ingame = ingame;
    //   app.lobby = lobby;
    //   app.vk = vk;
    //   app.pk = pk;
    //   app.players = players;
    //   app.Overlay = false;
    // });
    // socket.on('res-get_data', ()=>{
    //   app.state.getData = false;
    // });
    // socket.on('res-do_kick', ()=>{
    //   app.state.doKick = false;
    // });
  },
  beforeDestroy() {
    clearInterval(this.auto);
    this.auto = undefined;
  }
}
</script>

<style scoped>

</style>