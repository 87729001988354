<template>
  <v-container fluid>
    <v-alert
        dense
        :type="alert.type"
        timeout="5000"
        v-if="alert.message">
      {{ alert.message }}
    </v-alert>
		<div
			class="ma-1 pa-1"
		>
			<v-btn
				dark
				color="blue"
				@click="doBackToList()"
				v-if="!isListMode"
				class="mr-1"
			>
				Back to list
			</v-btn>
			<v-btn
					dark
					color="blue darken-2"
					@click="doFetchData()"
					class="mr-1"
			>
				Reload
			</v-btn>
			<v-btn
					dark
					color="green"
					@click="doLoadAllPhotos()"
					v-if="getPhotos.length > 0"
					class="mr-1"
			>
				Get All Photos
			</v-btn>
			<template v-if="selectedFiles.length > 0">
				<v-btn
						color="red"
						@click="doMultiDelete()"
						dark
						class="mr-1"
				>
					Delete All
				</v-btn>
			</template>
		</div>
    <v-data-table
				v-model="selectedFiles"
        :headers="headers"
        :items="items"
        :loading="Overlay"
        loading-text="Loading... Please wait"
        :footer-props="{'items-per-page-options':[15,30,50,100]}"
        :items-per-page="15"
				:item-key="isListMode ? 'name':'file'"
				show-select
		>
			<template v-slot:[`item.key`]="{ item }">
				<v-btn
					class="mx-2"
					color="blue"
					text
					@click="doLoadByKey(item)"
					v-if="isListMode"
				>
					{{ item.name }}
					<v-icon
						right
						dark
					>
						mdi-eye
					</v-icon>
				</v-btn>
				<v-btn
						class="mx-2"
						color="red"
						@click="doDelete(item.name)"
						v-if="isListMode"
				>
					Delete
				</v-btn>
			</template>
			<template v-slot:[`item.file`]="{ item }">
				<v-btn
						class="mx-2"
						color="blue"
						text
						@click="doLoadDataByKey(item)"
						v-if="!isListMode"
				>
					{{ item.file }}
				</v-btn>
				<v-btn
						class="mx-2"
						color="red"
						@click="doDelete(item.file)"
				>
					Delete
				</v-btn>
				<v-btn
						class="mx-2"
						color="green"
						@click="doFetchFile(item.file, true)"
						v-if="item.file.split('.').pop().toLowerCase() === 'txt'"
				>
					Download
				</v-btn>
			</template>
			<template v-slot:[`item.delete`]="{ item }">
				<v-btn
					class="mx-2"
					x-small
					color="orange"
					@click="doDelete(item)"
				>
					Delete
					<v-icon
						right
						dark
					>
						mdi-delete
					</v-icon>
				</v-btn>
			</template>
    </v-data-table>
    <v-dialog
        v-model="boxData"
        max-width="900px"
				persistent
    >
      <v-card flat>
        <v-card-title>DATA</v-card-title>
        <v-card-text>
          <v-textarea
              v-model="Item.content"
              label="Data"
              dark
							rows="20"
							v-if="fileType === 'txt'"
          />
					<viewer :images="ItemImages" v-else>
						<img v-for="src in ItemImages" :key="src" :src="src" height="120" width="120">
					</viewer>
        </v-card-text>
        <v-card-actions>
          <v-btn
              @click="boxData = false"
              dark
          >
            Close
          </v-btn>
					<v-spacer />
					<v-btn
						class="mx-2"
						color="green"
						@click="doDownload(ItemFile)"
						v-if="fileType === 'txt'"
					>
						Download
						<v-icon
							right
							dark
						>
							mdi-cloud-download
						</v-icon>
					</v-btn>
        </v-card-actions>

      </v-card>
      <v-overlay :value="loaderData">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </v-dialog>
    <v-overlay :value="Overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import 'viewerjs/dist/viewer.css'
import {getTextData, fetchData, downloadData} from '../helpers';
export default {
  name: "Logs",
  data(){
    return {
			selectedFiles: [],
      Overlay: false,
      boxData: false,
      loaderData: false,
      items: [],
			ItemFile: null,
			Item: {
				content: null,
				filename: null,
			},
			ItemImages:[],
    }
  },
  computed: {
    ...mapState({
      alert: state => state.alert
    }),
		isListMode(){
			return this.$route.params.key === undefined;
		},
		headers(){
			const headers = [
						{ text: 'Key', align: 'left', sortable: false,value: 'key',width:"auto" },
						{ text: 'Logs', align: 'center', sortable: false,value: 'logs',width: "auto" },
						{ text: 'Photos', align: 'center', sortable: false,value: 'photos',width: "auto" },
					]
			const headersByKey = [
				{ text: 'File', align: 'left', sortable: false,value: 'file',width:"auto" },
			]
			return this.isListMode ? headers : headersByKey;
		},
		fileType(){
			if(this.ItemImages.length > 0) return "jpg"
			if(this.Item.filename) {
				return this.Item.filename.split('.').pop().toLowerCase();
			}
			return null;
		},
		getPhotos(){
			return this.items.filter((item)=> item.file && item.file.split('.').pop().toLowerCase() === "jpg")
		}
  },
  methods:{
    ...mapActions('alert', ['success', 'error']),
		doBackToList(){
			this.items = [];
			this.$router.push(`/logs`);
			this.fetchData();
		},
		doLoadAllPhotos(){
			this.ItemImages = [];
			const photos = this.getPhotos;
			const tasks = photos.map((item)=> this.doFetchFile(item.file))
			Promise.all(tasks).finally(()=>{
				this.boxData = true;
			})
		},
		doLoadByKey(item){
			this.$router.push(`/logs/${item.name}`);
			this.doFetchListByKey(item.name);
		},
		doLoadDataByKey(item){
			this.Item = {
				content: null,
				filename: item.file,
			};
			if(this.fileType === 'jpg'){
				this.ItemImages = [];
			}
			this.doFetchFile(item.file)
		},
		doFetchListByKey: function(key){
			this.Overlay = true;
			fetchData(`${this.$API}/api/new-logs/${key}`).then((json)=>{
				this.items = json.data;
			},(err)=>{
				if(err === 401){
					this.logout();
				}else{
					this.error('SERVER ERROR !!!');
				}
			}).finally(()=>{
				this.Overlay = false;
			});
		},
		doFetchFile: function(file, isDownload = false){
			this.Overlay = true;
			let request = this.fileType === 'txt' || file.split('.').pop().toLowerCase() === 'txt' ? getTextData : fetchData;
			if(isDownload === true) request = downloadData;
			request(`${this.$API}/api/new-logs/${this.$route.params.key}`,{ file }).then((data)=>{
				if(isDownload === false) {
					if (this.fileType === 'jpg' || file.split('.').pop().toLowerCase() === 'jpg') {
						if (data.data) {
							this.ItemImages.push(data.data);
						} else {
							this.error('File not found !!!');
						}
					} else {
						this.Item.content = data;
					}
					this.boxData = true;
				}else{
					const url = window.URL.createObjectURL(data);
					const a = document.createElement('a');
					a.href = url;
					a.download = file;
					document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
					a.click();
					a.remove();
					this.success('Downloaded Successfully');
				}
			},(err)=>{
				if(err === 401){
					this.logout();
				}else{
					this.error('SERVER ERROR !!!');
				}
			}).finally(()=>{
				this.Overlay = false;
			});
		},
    fetchData: function(request){
      this.Overlay = true;
      fetchData(`${this.$API}/api/new-logs`,request).then((json)=>{
        this.items = json.data;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      }).finally(()=>{
        this.Overlay = false;
      });
    },
		doDownload: function(){
			this.Overlay = true;
			this.loaderData = true;
			const blob = new Blob([this.Item.content], { type : 'plain/text' });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = this.Item.filename;
			document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
			a.click();
			a.remove();
			this.success('Downloaded Successfully');
			this.Overlay = false;
			this.loaderData = false;
		},
    doDelete: function(file){
      this.$confirm(`Do you really want to do this action ? This data can't restore !`,{ title: 'Warning' }).then((resp)=>{
        if(resp === true){
          this.deleteData(file)
        }
      });
    },
		doMultiDelete: function(){
			const app = this;
			const key = this.isListMode ? 'name' : 'file';
			const deleteData = app.selectedFiles.map(item=>item[key]);
			const apiEndpoint = this.isListMode ? 'api/new-logs' : `api/new-logs/${this.$route.params.key}`;
			this.$confirm(`Do you really want to do this action ? This data can't restore !`,{ title: 'Warning' }).then((resp)=>{
				if(resp === true){
					app.Overlay = true;
					fetchData(`${app.$API}/${apiEndpoint}`,{dels:deleteData}).then((json)=>{
						const {error,success} = json;
						if(error){
							if(error === 404){
								app.error(`Data Not Found`);
							}
							if(error === 400){
								app.error(`Data Invalid`);
							}
						}else if(success){
							app.success(`Data Removed`);
							deleteData.map(item=>{
								app.items = app.items.filter((f)=> f[key] !== item);
							})
							app.selectedFiles = [];
							if(json['empty_dir']){
								app.doBackToList();
							}
						}else{
							app.error(`Server Error`);
						}
					},(err)=>{
						if(err === 401){
							app.logout();
						}else{
							app.error('SERVER ERROR !!!');
						}
					}).finally(()=>{
						app.loaderData = false;
						app.Overlay = false;
					});
				}
			});
		},
    deleteData: async function(file){
			this.Overlay = true;
			const key = this.isListMode ? 'name' : 'file';
			const apiEndpoint = this.isListMode ? 'api/new-logs' : `api/new-logs/${this.$route.params.key}`;
			fetchData(`${this.$API}/${apiEndpoint}`, {dels: [file]}).then((json) => {
				const {error, success} = json;
				if (error) {
					if (error === 404) {
						this.error(`DATA Not Found`);
					}
					if (error === 400) {
						this.error(`DATA Invalid`);
					}
				} else if (success) {
					this.success(`DATA Removed`);
					this.items = this.items.filter((f)=> f[key] !== file);
					if(json['empty_dir']){
						this.doBackToList();
					}
				} else {
					this.error(`Server Error`);
				}
			}, (err) => {
				if (err === 401) {
					this.logout();
				} else {
					this.error('SERVER ERROR !!!');
				}
			}).finally(() => {
				this.loaderData = false;
				this.Overlay = false;
			});
    },
		doFetchData(){
			if(this.isListMode) this.fetchData();
			else this.doFetchListByKey(this.$route.params.key);
		}
  },
  mounted(){
		this.doFetchData()
  }
}
</script>

<style scoped>

</style>